<template>
    <div>
        <h1 style="color: #72a5d8; font-weight: bolder; font-size: xx-large" class="mb-5 ">Moj profil</h1>

        <b-card v-if="!emailConfirmed" class="text-center  mx-3" style="background: #eaf3f9 !important; border-radius: 10px; box-shadow: none !important;">
            <div class="row">
                <div class="col-12" style="font-size: 1.5rem; font-weight: bold;">
                    Potrdite svoj email naslov.
                </div>
                <div class="col-12">
                    Ker niste še potrdili svojega email naslova ne morete uporabljati spodnjih funkcionalnosti!.
                </div>
            </div>
        </b-card>

        <div class="grid-container" style="background-color: white;">
            <BButton :disabled="!emailConfirmed" class="button-card py-2 grid-item" @click="$router.push({name: 'my-data'})">
                <div>
                    <div class="d-flex justify-content-center">
                        <fa icon="user" class="big_icon pb-1"/>
                    </div>
                    <div class="d-flex justify-content-center">
                        <h1 class="text-xl" style="color: white">Moji podatki</h1>
                    </div>
                </div>
            </BButton>
            <BButton :disabled="!emailConfirmed" class="button-card py-2 grid-item" @click="$router.push({name: 'my-coupons'})">
                <div>
                    <div class="d-flex justify-content-center">
                        <fa icon="tag" class="big_icon fa-flip-horizontal pb-1"/>
                    </div>
                    <div class="d-flex justify-content-center">
                        <h1 class="text-xl" style="color: white">Moji kuponi</h1>
                    </div>
                </div>
            </BButton>
            <BButton :disabled="!emailConfirmed" class="button-card py-2 grid-item" @click="$router.push({name: 'my-orders'})">
                <div>
                    <div class="d-flex justify-content-center">
                        <fa icon="file" class="big_icon pb-1"/>
                    </div>
                    <div class="d-flex justify-content-center">
                        <h1 class="text-xl" style="color: white">Moja naročila</h1>
                    </div>
                </div>
            </BButton>
            <BButton :disabled="!emailConfirmed" class="button-card py-2 grid-item" @click="$router.push({name: 'my-ads'})">
                <div>
                    <div class="d-flex justify-content-center">
                        <fa icon="newspaper" class="big_icon pb-1"/>
                    </div>
                    <div class="d-flex justify-content-center">
                        <h1 class="text-xl" style="color: white">Moji Oglasi</h1>
                    </div>
                </div>
            </BButton>
        </div>
        <div class="d-flex justify-content-center" style="margin-top: 12rem">
            <BButton style="font-size: x-large" pill class="px-4" @click="logout">Odjava</BButton>
        </div>
    </div>
</template>

<script>
    import {BButton, BCard} from 'bootstrap-vue'
    import authService from '@/auth/authService'
    export default {
        components:{BButton, BCard},
        computed:{
            emailConfirmed() {
                return this.$store.getters['user/emailConfirmed']
            }
        },
        methods:{
            logout() {
                authService.logOut()
                this.$router.push({name: 'login'})
            }
        }
    }
</script>

<style scoped>

.grid-item {
    background-color: #e6e7e6;
    font-size: 16px;
    text-align: center;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    margin-bottom: 1rem;
}

@media screen and (max-width: 450px) {
    .grid-container {
        display: grid;
        gap: 0rem;
        grid-row-gap: 2rem;
        grid-template-columns: none;
        grid-template-rows: auto auto auto;
    }
}

@media screen and (min-width: 950px) {
    .grid-container {
        gap: 2rem;
        display: grid;
        grid-template-columns: auto auto auto ;
        grid-template-rows: none;
    }
}

.big_icon {
    height: 7rem;
    width: 7rem;
    color: white;
}

.button-card {
    background-color: #169ed4;
    border-radius: 0px !important;
    width: 100%;
    /* box-shadow: rgba(0, 0, 0, 0.39) 10px 10px 10px, rgba(0, 0, 0, 0.23) 8px 8px 8px; */
}

</style>